import axios from 'axios';

const client = axios.create({
  baseURL: 'https://pms-api-e0fe843b504e.herokuapp.com/',
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 100000,
});

export function get<P>(url: string, params?: P): Promise<any> {
  return client({
    method: 'get',
    url,
    params,
  });
}

export function post<D, P>(url: string, data?: D, params?: P): Promise<any> {
  return client({
    method: 'post',
    url,
    data,
    params,
  });
}

export function put<D>(url: string, data: D): Promise<any> {
  return client({
    method: 'put',
    url,
    data,
  });
}

export function patch<D>(url: string, data: D): Promise<any> {
  return client({
    method: 'patch',
    url,
    data,
  });
}

export function remove<P>(url: string, params: P): Promise<any> {
  return client({
    method: 'delete',
    url,
    params,
  });
}
